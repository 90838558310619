import defaultLogo from '../logo.png';

export function Navbar({ children, logo = defaultLogo }) {
    return (
        <>
            <div className={'flex justify-between items-center p-4 border-b border-b-gray-400 shadow-md mb-3'}>
                <div>
                    <img src={logo} className='img-fluid navbar-logo' />
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="none">
                        <path
                            d="M2 3C1.60218 3 1.22064 2.84196 0.93934 2.56066C0.658035 2.27936 0.5 1.89782 0.5 1.5C0.5 1.10218 0.658035 0.720644 0.93934 0.43934C1.22064 0.158035 1.60218 -8.29566e-08 2 -6.55671e-08C2.39782 -4.81776e-08 2.77936 0.158035 3.06066 0.43934C3.34196 0.720644 3.5 1.10218 3.5 1.5C3.5 1.89782 3.34196 2.27936 3.06066 2.56066C2.77936 2.84196 2.39782 3 2 3ZM2 10.5C1.60217 10.5 1.22064 10.342 0.93934 10.0607C0.658035 9.77935 0.5 9.39782 0.5 9C0.5 8.60218 0.658035 8.22064 0.93934 7.93934C1.22064 7.65804 1.60217 7.5 2 7.5C2.39782 7.5 2.77936 7.65804 3.06066 7.93934C3.34196 8.22064 3.5 8.60218 3.5 9C3.5 9.39782 3.34196 9.77936 3.06066 10.0607C2.77935 10.342 2.39782 10.5 2 10.5ZM2 18C1.60217 18 1.22064 17.842 0.939339 17.5607C0.658035 17.2794 0.499999 16.8978 0.499999 16.5C0.499999 16.1022 0.658035 15.7206 0.939339 15.4393C1.22064 15.158 1.60217 15 2 15C2.39782 15 2.77935 15.158 3.06066 15.4393C3.34196 15.7206 3.5 16.1022 3.5 16.5C3.5 16.8978 3.34196 17.2794 3.06066 17.5607C2.77935 17.842 2.39782 18 2 18Z"
                            fill="#343644" />
                    </svg>
                </div>
            </div>
            {children}
        </>
    )
}
